import './SearchPlaceholder.scss';

import { appendClassNames, createClsNameMap } from '@utils';

export const clsNameMap = createClsNameMap({
  elements: {
    list: createClsNameMap({}),
    item: createClsNameMap({}),
    img: createClsNameMap({}),
  },
})('search-placeholder');

export type SearchPlaceholderProps = {
  className?: string;
  rows?: number;
};

const SearchPlaceholder = ({ className, rows = 3 }: SearchPlaceholderProps) => {
  const arr = new Array(rows).fill('item');
  return (
    <div className={appendClassNames(clsNameMap.root(), className)}>
      <ul className={clsNameMap.elm('list')}>
        {arr.map((_, i) => {
          return (
            <li key={i} className={clsNameMap.elm('item')}>
              <div className={clsNameMap.elm('img')}></div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SearchPlaceholder;
