import type { ProductListItem } from '@providers/ProductListProvider';
import { useMemo } from 'react';

export const useProduct = (product: ProductListItem, noImageUrl?: string) => {
  const labelByTag = useMemo(() => {
    return product.tags
      ?.filter((item) => item.indexOf('pfs:label') !== -1)
      .map((item) => item.split('pfs:label-')[1]);
  }, [product]);

  const isSoldOut = !product.available;

  const previewProductImages = useMemo(() => {
    const productInfo = product.images_info;

    if (!productInfo.length) {
      return [noImageUrl || ''];
    }

    if (productInfo.length === 1) {
      return [productInfo[0].src];
    }

    return [productInfo[0].src, productInfo[1].src];
  }, [product]);

  const isSale = useMemo(() => {
    return (product.compare_at_price_min || 0) > product.price_min;
  }, [product]);

  const salePercent = useMemo(() => {
    return isSale && product.compare_at_price_min
      ? Math.round(
          (((product.compare_at_price_min || 0) - product.price_min) * 100) /
            product.compare_at_price_min
        ) + '%'
      : undefined;
  }, [product]);

  const saleAmount = useMemo(() => {
    return isSale ? (product.compare_at_price_min || 0) - product.price_min : undefined;
  }, [product]);

  const firstImage = product.images_info.length > 0 ? product.images_info[0] : undefined;

  return {
    firstImage,
    labelByTag,
    isSoldOut,
    isSale,
    salePercent,
    saleAmount,
    previewProductImages,
  };
};

export default useProduct;
