const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const awaitAnimationElementFinished = async (
  htmlElement: HTMLElement,
  step = 100, // ms
  maxMs = 1000 // ms
) => {
  if (!htmlElement) {
    // eslint-disable-next-line no-console
    console.error('awaitAnimationElementFinished htmlElement not found');
    return Promise.resolve();
  }

  const _pos = [];
  const maxLoop = Math.floor(maxMs / step);
  for (let i = 0; i < maxLoop; i++) {
    const _inputPos = htmlElement.getBoundingClientRect();
    _pos.push({
      x: _inputPos.x,
      y: _inputPos.y,
    });

    if (
      i > 0 &&
      _pos[i].x >= 0 &&
      _pos[i].y >= 0 &&
      _pos[i].x === _pos[i - 1].x &&
      _pos[i].y === _pos[i - 1].y
    ) {
      break;
    }

    await sleep(step);
  }

  return Promise.resolve();
};
