import { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';

export type VDomToStringProps = {
  vDOM: React.ReactNode;
  children: React.ReactNode | ((payload: { html: string }) => React.ReactNode);
};

export const useVDomToString = ({ vDOM }: Pick<VDomToStringProps, 'vDOM'>) => {
  const [state, setState] = useState<string | null>(null);

  useEffect(() => {
    const div = document.createElement('div');
    const root = createRoot(div);

    setTimeout(() => {
      flushSync(() => {
        root.render(vDOM);
      });
      setState(div.innerHTML);
      root.unmount();
    });

    return () => {
      setTimeout(() => {
        root.unmount();
      });
    };
  }, [vDOM]);

  return state;
};

// TODO not render children - need debug before can use it
export const VDomToString = ({ children, vDOM }: VDomToStringProps) => {
  const string = useVDomToString({ vDOM });

  const onRender = () => {
    if (!string) return null;

    if (typeof children === 'function') {
      children({
        html: string,
      });
    }

    return children;
  };

  return <>{onRender()}</>;
};

export default VDomToString;
