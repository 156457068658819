import './ViewAll.scss';

import { appendClassNames, createClsNameMap } from '@utils';
export const clsNameMap = createClsNameMap({
  elements: {
    shadow: createClsNameMap(),
    link: createClsNameMap(),
    text: createClsNameMap(),
    icon: createClsNameMap(),
  },
})('view-all');

export type ViewAllProps = {
  isShow: boolean;
  className?: string;
  textContent: string;
  link: string;
  iconComponent?: React.ReactElement | null;
  dataGroup?: string;
};
const ViewAll = (props: ViewAllProps) => {
  const { isShow, className, textContent, link, iconComponent, dataGroup } = props;

  if (!isShow) return null;

  return (
    <div className={appendClassNames(clsNameMap.root(), className)} data-group={dataGroup}>
      <a className={clsNameMap.elm('link')} href={link}>
        <span className={clsNameMap.elm('text')}>
          {textContent}
          {iconComponent}
        </span>
      </a>
    </div>
  );
};

export default ViewAll;

ViewAll.defaultProps = {
  dataGroup: 'view-all-products',
  iconComponent: (
    <svg
      className={clsNameMap.elm('icon')}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.1657 7.43443L10.1657 3.43443C9.8529 3.12163 9.3473 3.12163 9.0345 3.43443C8.7217 3.74723 8.7217 4.25283 9.0345 4.56563L11.6689 7.20003H2.4001C1.9577 7.20003 1.6001 7.55843 1.6001 8.00003C1.6001 8.44163 1.9577 8.80003 2.4001 8.80003H11.6689L9.0345 11.4344C8.7217 11.7472 8.7217 12.2528 9.0345 12.5656C9.1905 12.7216 9.3953 12.8 9.6001 12.8C9.8049 12.8 10.0097 12.7216 10.1657 12.5656L14.1657 8.56563C14.4785 8.25283 14.4785 7.74723 14.1657 7.43443'
        fill='#5C5F62'
      ></path>
    </svg>
  ),
};
