import './SearchProducts.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import type { ReactNodeRenderer } from '@components/CustomizableNode';
import CustomizableNode from '@components/CustomizableNode';
import HeaderItem from '@components/HeaderItem';
import SearchProductItem from '@components/SearchProductsItem';
import useTranslation from '@hooks/useTranslation';
import type { ProductListItem } from '@providers/ProductListProvider';
import { appendClassNames, createClsNameMap } from '@utils';

export const clsNameMap = createClsNameMap({
  elements: {
    group: createClsNameMap(),
    items: createClsNameMap(),
    header: createClsNameMap({
      modifiers: ['product'],
    }),
  },
})('instant-search-product-list');

export type SearchProductListType = 'most' | 'trending' | 'product';

export type SearchProductListProps = {
  className?: string;
  products: Array<ProductListItem> | null | undefined;
  headerTitle: string;
  type?: SearchProductListType;
  onRenderHeaderItem?: ReactNodeRenderer;
};
const SearchProductList = (props: SearchProductListProps) => {
  const { t } = useTranslation();

  const { products, headerTitle, className, onRenderHeaderItem } = props;

  if (!Array.isArray(products) || !products || products?.length <= 0) return null;

  return (
    <div className={appendClassNames(clsNameMap.root(), className)}>
      <CustomizableNode renderer={onRenderHeaderItem}>
        <HeaderItem
          className={clsNameMap.elm('header')}
          value={headerTitle || t('search.searchPanelProduct')}
        />
      </CustomizableNode>

      <ul role='listbox' className={clsNameMap.elm('items')}>
        {products.map((product: ProductListItem, index: number) => {
          return <SearchProductItem key={product.id} product={product} />;
        })}
      </ul>
    </div>
  );
};

export default registryComponent('SearchProductList', SearchProductList);
