import './NoSearchResult.scss';

import { createClsNameMap, mapModifiers } from '@utils';
import { Trans } from 'react-i18next';

export const clsNameMap = createClsNameMap({
  modifiers: ['havePaddingBottom'],
  elements: {
    desc: createClsNameMap(),
  },
})('no-search-result');

export type NoSearchResultProps = {
  searchTerm: string;
  keyTranslationTerm?: string;
  havePaddingBottom: boolean;
};

const NoSearchResult = ({
  searchTerm,
  keyTranslationTerm = 'search.resultEmptyWithSuggestion',
  havePaddingBottom,
}: NoSearchResultProps) => {
  if (!searchTerm) return null;

  const strongSearchTerm = `<strong>${searchTerm}</strong>`;

  return (
    <div
      className={mapModifiers(clsNameMap, {
        havePaddingBottom,
      })}
      data-group='empty'
      data-aria-label='No Results'
      data-label={`No Results: ${searchTerm}`}
    >
      <p className={clsNameMap.elm('desc')}>
        <Trans
          i18nKey={keyTranslationTerm}
          values={{ terms: strongSearchTerm, breakline: '<br />' }}
          components={{ strong: <strong /> }}
        />
      </p>
    </div>
  );
};

export default NoSearchResult;
