import HeaderItem from '@components/HeaderItem';
import HighLightText from '@components/HighLightText';
import { setOnClickRecentSearches } from '@constants/instantSearchClick';
import type { Dict } from '@types';
import { createClsNameMap, generateSearchPageHref, mapModifiers } from '@utils';

export const clsNameMap = createClsNameMap({
  elements: {
    group: createClsNameMap(),
    list: createClsNameMap(),
    item: createClsNameMap({
      elements: {
        selected: createClsNameMap(),
      },
      modifiers: ['mobile-style1'],
    }),
    header: createClsNameMap({
      modifiers: ['page'],
    }),
    link: createClsNameMap(),
  },
})('suggestion-queries'); // don't change name suggestion-queries this line

export type PageBlogResultProps = {
  list: Array<Dict | string> | undefined;
  query: string;
  headerTitle: string;
  isMobileStyle1?: boolean;
  isHighlight?: boolean;
  type?: 'page' | 'redirect';
};

const PageBlogResult = ({
  list,
  query,
  headerTitle,
  isMobileStyle1,
  isHighlight = false,
  type = 'page',
}: PageBlogResultProps) => {
  if (!list || list?.length === 0) return null;

  const buildTitle = (suggestion: string | Dict) => {
    if (typeof suggestion === 'string') return suggestion;
    return suggestion.title;
  };

  const buildUrl = (item: string | Dict) => {
    if (typeof item === 'string') {
      return generateSearchPageHref(item);
    }

    return item.url;
  };

  const modifierByType = () => {
    if (type) return { [type]: true };

    return {};
  };

  return (
    <div className={clsNameMap.elm('group')}>
      {headerTitle && (
        <HeaderItem
          className={mapModifiers(clsNameMap.header, {
            ...modifierByType(),
          })}
          value={headerTitle}
        />
      )}
      <ul role='listbox' className={clsNameMap.elm('list')}>
        {list.map((item: string | Dict, i: number) => {
          return (
            <li
              key={i}
              className={mapModifiers(clsNameMap.item, {
                'mobile-style1': isMobileStyle1,
              })}
              aria-label={buildTitle(item)}
              aria-selected={'false'}
              role={'option'}
              data-title={buildTitle(item)}
              onClick={() => setOnClickRecentSearches(buildTitle(item))}
            >
              <a tabIndex={-1} href={buildUrl(item)} className={clsNameMap.elm('link')}>
                <HighLightText
                  text={buildTitle(item)}
                  highlight={query}
                  enableHighLight={isHighlight}
                />
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PageBlogResult;
