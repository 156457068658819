import { stripHtml } from '@utils';

export type HighLightTextProps = {
  text: string;
  highlight: string;
  enableHighLight: boolean;
};
const HighLightText = ({ text, highlight, enableHighLight }: HighLightTextProps) => {
  if (!text || !highlight || !enableHighLight) {
    return <>{text}</>;
  }

  // tripHtml hightlight
  highlight = stripHtml(highlight);
  // Split text on highlight term, include term itself into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <>
      {parts.map((part, i) => {
        return (
          <span key={i}>
            {part.toLowerCase() === highlight.toLowerCase() ? <strong>{part}</strong> : <>{part}</>}
          </span>
        );
      })}
    </>
  );
};

export default HighLightText;
