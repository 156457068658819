import './FormatCurrency.scss';

import type { RegistryComponentProps } from '@boost-sd/components-registry';
import { registryComponent } from '@boost-sd/components-registry/registry';
import { InnerHTMLRenderer } from '@components/InnerHTMLRenderer';
import { createClsNameMap, formatMoney, mapModifiers } from '@utils';
import { useImperativeHandle, useMemo } from 'react';

export const clsNameMap = createClsNameMap({
  modifiers: ['price-compare', 'price-sale'],
})('format-currency');

export type FormatCurrencyProps = RegistryComponentProps<
  {
    value: string | number | null | undefined;
    formatWithCurrency?: boolean;
    showCentAsSuperscript?: boolean;
    removeDecimalPoint?: boolean;
    isPriceCompare?: boolean;
    removePriceDecimal?: boolean;
    style?: Record<string, unknown>;
    isSale?: boolean;
    appendClassName?: string;
  },
  {
    formatMoney: typeof formatMoney;
  }
>;

const FormatCurrency: React.FC<FormatCurrencyProps> = ({
  value,
  formatWithCurrency,
  showCentAsSuperscript,
  removeDecimalPoint,
  isPriceCompare,
  removePriceDecimal,
  style,
  isSale,
  appendClassName,
  helpersRef,
}: FormatCurrencyProps) => {
  useImperativeHandle(helpersRef, () => ({
    formatMoney,
  }));

  const moneyFormat = useMemo(() => {
    if (value === null || value === undefined) return null;

    const formatType = formatWithCurrency
      ? window.boostSDAppConfig?.shop?.money_format_with_currency
      : window.boostSDAppConfig?.shop?.money_format;

    return formatMoney(
      Number(value),
      formatType,
      false,
      showCentAsSuperscript,
      removePriceDecimal,
      removeDecimalPoint
    );
  }, [value, formatWithCurrency, showCentAsSuperscript, removePriceDecimal, removeDecimalPoint]);

  if (!moneyFormat) return <></>;

  return (
    <InnerHTMLRenderer
      style={style}
      as='span'
      className={mapModifiers(clsNameMap, {
        'price-compare': isPriceCompare,
        'price-sale': isSale,
      })}
      html={`<span class='${appendClassName ?? ''}'>${moneyFormat}</span>`}
    />
  );
};

export default registryComponent('FormatCurrency', FormatCurrency);
