import './SearchInput.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import { clsNameMap as suggestionTermCls } from '@components/SuggestionQueries';
import { setOnClickRecentSearches } from '@constants/instantSearchClick';
import { ALL_REDIRECTS_KEY } from '@constants/instantSearchRedirect';
import useGeneralSettings from '@hooks/useGeneralSettings';
import { BOOST_SD_SEARCH_RESULTS_CACHE } from '@hooks/useLocalStorage';
import useMountEffect from '@hooks/useMountEffect';
import useTranslation from '@hooks/useTranslation';
import { useSearchSettings, useSearchState } from '@providers/SearchProvider/Provider';
import {
  awaitAnimationElementFinished,
  generateSearchPageHref,
  getBoostThemeLib,
  getLocalStorage,
  getTermValueFromUrl,
  isBadSearchTerm,
  isBadUrl,
  isCollectionPage,
  isMobileWidth,
  setWindowLocation,
  stripHtml,
} from '@utils';
import classnames, { createClsNameMap } from '@utils/classnames';
import { debounce } from 'lodash-es';
import { useEffect } from 'react';

import { clsNameMap as suggestionQueryCls } from '@/components/SuggestionQueries';
import { clsNameMap as searchBarCls, idsNameMap as searchBarIds } from '@/widgets/SWSearchBar';
import { clsNameMap as autocompleteCls } from '@/widgets/SWSearchContentResult';

export const clsNameMap = createClsNameMap({
  elements: {
    'cursor-pointer': createClsNameMap(),
  },
})('search-input');

export const searchFormCls = createClsNameMap({
  elements: {
    input: createClsNameMap(),
  },
})('search-form');

export type SearchInputProps = {
  id: string;
  idSuggestionResults: string;
  isOwnerInput?: boolean; // = true when input is searchBar
  hasSearchModal?: boolean; // default true -> cover theme have modal search, if theme haven't modal search set = false
  closeSearchThemeSelector?: string;
  isFocalTheme?: boolean;
};

let keyboardNavIndex = -1;
let currentSearch = '';

const SearchInput = ({
  id,
  idSuggestionResults,
  isOwnerInput,
  hasSearchModal = true,
  closeSearchThemeSelector,
  isFocalTheme,
}: SearchInputProps) => {
  const { t } = useTranslation();

  const { changeSearchTerm, setSearchTerm, setCurrentSearchContentResultActive, isDirty } =
    useSearchState();
  const {
    settings: {
      suggestionStyle,
      suggestionMobileStyle,
      suggestionMaxHeight,
      suggestionStyle2ProductItemType,
    },
  } = useSearchSettings();

  const {
    generalSettings: { termKey },
  } = useGeneralSettings();

  const isMobile = isMobileWidth();
  const boostThemeLib = getBoostThemeLib();

  const getApiResultsReturned = (currentSearchTerm: string) => {
    const _value = getLocalStorage(BOOST_SD_SEARCH_RESULTS_CACHE);

    if (!_value) return false;

    return _value[currentSearchTerm];
  };

  useMountEffect(function initEvents() {
    const thisInput = document.getElementById(id) as HTMLInputElement;
    const thisSuggestionResults = document.getElementById(idSuggestionResults);
    const inTranslationText = ` ${t('suggestion.in')} `;

    // check theme passed id of Input and idSuggestionResults
    if (!thisInput) {
      throw Error(
        `Please check Id = ${id} input not found in current page. thisInput = ${thisInput}`
      );
    }

    if (!thisSuggestionResults) {
      throw Error(
        `Please check idSuggestionResults = ${idSuggestionResults} not found in current page. SuggestionResults = ${thisSuggestionResults}`
      );
    }

    // default add hide class for suggestion
    thisSuggestionResults.classList.add(classnames('g-hide'));

    // disabled autocomplete of chrome
    thisInput.setAttribute('autocomplete', 'off');
    thisInput.setAttribute('data-search-box', id);
    thisInput.setAttribute('role', 'combobox');
    thisInput.setAttribute('aria-expanded', 'false');
    thisInput.setAttribute('aria-autocomplete', 'list');
    thisInput.setAttribute('aria-owns', idSuggestionResults);
    thisInput.setAttribute('aria-label', t('ada.searchAutoComplete'));
    thisInput.setAttribute('maxlength', '150');
    thisInput.setAttribute('placeholder', t('suggestion.searchBoxPlaceholder'));
    thisInput.setAttribute('value', getTermValueFromUrl(termKey));

    // check current input is not input of owner then check style
    if (!isOwnerInput) {
      if (isStyleFullWidth()) {
        //cover case use for modal input: => show mobile instant search or full-width
        thisInput.addEventListener('focus', function (e: Event) {
          preventAllEvents(e);
          onOpenSearchBarFullWidth();
        });

        // click input => show mobile instant search or full-width
        thisInput.classList.add(clsNameMap.elm('cursor-pointer'));
        thisInput.addEventListener('click', function (e: Event) {
          preventAllEvents(e);
          onOpenSearchBarFullWidth();
        });

        return;
        // style full-width - style3
      }
    }

    // bind events

    /**
     * Bind the input change event on input
     * @param {Event} event The event
     */
    thisInput.addEventListener('input', (e) => {
      handleInputChange(e, thisInput, thisSuggestionResults);
    });

    /**
     * Bind the keypress event on input
     * @param {Event} event The keyboard event
     */
    thisInput.addEventListener('keydown', (e) => {
      if (!isSuggestionOpening(thisSuggestionResults) || !e || !e.key) return;

      // Enter key
      if (e.key === 'Enter') {
        preventAllEvents(e);

        if (isXSSSearchTerm(e, thisInput.value)) return;

        const _currentValue = thisInput?.value.trim();

        if (_currentValue?.includes(inTranslationText)) {
          // scoped suggestion
          const item = document.querySelector(
            `.${suggestionQueryCls.elm('item')}[aria-selected="true"]`
          );

          const extraParam = item?.getAttribute('data-extra-param') || '';
          const [suggestion, scope] = _currentValue.split(inTranslationText);

          setOnClickRecentSearches(suggestion, extraParam, scope);
        } else {
          setOnClickRecentSearches(_currentValue);
        }

        const redirects = getLocalStorage(ALL_REDIRECTS_KEY);

        // api returned
        if (_currentValue.length > 0) {
          if (getApiResultsReturned(_currentValue)) {
            // check direct when enter
            if (redirects && redirects[_currentValue?.toLowerCase()]) {
              setWindowLocation(redirects[_currentValue?.toLowerCase()]);
            } else {
              // check if have current linkSelected then redirect else default search term
              const linkSelected = selectItemCurrentSelected(thisSuggestionResults);
              setWindowLocation(linkSelected || generateSearchPageHref(_currentValue));
            }
          } else {
            const linkSelected = selectItemCurrentSelected(thisSuggestionResults);

            if (linkSelected) {
              setWindowLocation(linkSelected);
            }
          }
        } else {
          // Redirect to search page with empty search term
          setWindowLocation(generateSearchPageHref());
        }
      }

      // current focus input, and Tab -> move to other element -> close
      if (e.key === 'Tab') {
        onCloseSuggestion(thisSuggestionResults, thisInput);
      }

      // WCAG: Space = select current query search
      if (e.key === ' ') {
        setSearchTerm(thisInput.value);
      }

      // esc key => close
      if (e.key === 'Escape') {
        return onCloseSuggestion(thisSuggestionResults, thisInput);
      }

      //Arrow/navigation key up, down, left, right
      if (
        ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key) &&
        isSuggestionOpening(thisSuggestionResults)
      ) {
        onKeyboardNavigation(thisInput, thisSuggestionResults, e);
      }
    });

    const debounceDocumentClick = debounce((e) => {
      if (isSuggestionOpening(thisSuggestionResults)) {
        // click Outside check close suggestion results
        clickOutsideSuggestion(thisSuggestionResults, thisInput, e);
      }
    }, 100);

    /**
     * Bind the click event on document for save Recent Search and close suggestion
     * @param {Event} event The mouse event
     * true = event call before event click no have true, override some event click preventDefault
     */
    document.addEventListener('click', debounceDocumentClick, true);

    const debounceResize = debounce(() => {
      if (isSuggestionOpening(thisSuggestionResults)) {
        calcPositionSuggestionResult(thisInput, thisSuggestionResults);
      }
    }, 100);

    /**
     * Bind the resize event on window for re-calculate position of suggestion with input
     * @param {Event} event The mouse event
     */
    window.addEventListener('resize', debounceResize);

    // detect search button
    const thisSearchForm = thisInput.closest('form');
    if (thisSearchForm) {
      const thisButtonSearch = thisSearchForm.querySelector('[type="submit"], .search__button');

      if (thisButtonSearch) {
        thisButtonSearch.addEventListener('click', (e) => {
          e.preventDefault();

          // save recent search when click button search
          setOnClickRecentSearches(stripHtml(thisInput.value));

          // check redirect when click button search
          const redirects = getLocalStorage(ALL_REDIRECTS_KEY);

          if (redirects && redirects[thisInput.value?.toLowerCase()]) {
            preventAllEvents(e);
            setWindowLocation(redirects[thisInput.value?.toLowerCase()]);
            return;
          }

          setWindowLocation(generateSearchPageHref(thisInput.value));
        });
      }
    }
  });

  useEffect(() => {
    const thisInput = document.getElementById(id) as HTMLInputElement;
    const thisSuggestionResults = document.getElementById(idSuggestionResults);

    // handle edge case when clear (x) term - search form
    const isSearchForm = thisInput?.getAttribute('data-type');

    // check theme passed id of Input and idSuggestionResults
    if (!thisInput || !thisSuggestionResults) {
      return;
    }

    /**
     * Bind the focus and click event on input
     * @param {Event} event The Focus/Click event
     */

    const listener = async (e: Event) => {
      thisInput.click();
      let currentValue = thisInput.value;
      if (!currentValue && !isDirty && !isSearchForm) {
        currentValue = getTermValueFromUrl(termKey);
        thisInput.value = currentValue;
      }

      setSearchTerm(currentValue);
      // await modal search display
      await awaitAnimationElementFinished(thisInput, 100, 1000);

      // tips: trigger click thisSuggestionResults, hide search of theme when use style full Width.
      if (isStyleFullWidth()) {
        thisSuggestionResults.click();
      }
      handleInputChange(e, thisInput, thisSuggestionResults);
      calcPositionSuggestionResult(thisInput, thisSuggestionResults);
      onOpenSuggestion(thisSuggestionResults, thisInput);
    };

    ['focus', 'click'].forEach((eventName) => {
      thisInput.addEventListener(eventName, listener);
    });

    // open ISW if user typed something before build scripts get executed
    if (thisInput.value && isCollectionPage() && !getTermValueFromUrl(termKey)) {
      thisInput.click();
    }

    if (boostThemeLib === 'empire' && isMobile && thisInput.id.includes('input-0')) {
      const searchIcon = document.querySelector('.site-header-mobile-search-button--button');
      const searchBarInput = document.querySelector(
        `.${searchBarIds.elm('input')}`
      ) as HTMLInputElement;

      searchIcon?.addEventListener('click', () => {
        thisInput.click();

        setTimeout(() => searchBarInput.focus(), 200);
      });
    }

    return () => {
      ['focus', 'click'].forEach((eventName) => {
        thisInput.removeEventListener(eventName, listener);
      });
    };
  }, [isDirty]);

  const handleInputChange = (e: Event, input: HTMLInputElement, suggestionResults: HTMLElement) => {
    e.preventDefault();
    keyboardNavIndex = -1;
    removeCurrentSelected(input, suggestionResults);

    changeSearchTerm(input.value);
    currentSearch = input.value;
  };

  const clickOutsideSuggestion = (
    suggestionResults: HTMLElement,
    input: HTMLInputElement,
    e: Event
  ) => {
    // not apply style full width
    if (isStyleFullWidth()) return;

    let doClose = false;

    if (isSuggestionOpening(suggestionResults) && e && e.target) {
      const activeElement = e.target as HTMLElement;
      const isClickThisInput = activeElement?.id === id;
      const isClickThisSearchButton =
        activeElement.closest('form') && activeElement.closest('[type="submit"]');
      const isClickSuggestion = activeElement.closest(`#${idSuggestionResults}`);

      if (!isClickThisInput && !isClickThisSearchButton && !isClickSuggestion) {
        doClose = true;
      }

      // if click Suggestion & theme not turn off hasSearchModal
      if (isClickSuggestion && hasSearchModal) {
        doClose = true;
      }
    } else if (!e) {
      doClose = true;
    }

    if (doClose) {
      onCloseSuggestion(suggestionResults, input);
      document.body.classList.remove(classnames('search-opening'));
    } else {
      if (!input.classList.contains(searchFormCls.elm('input'))) {
        document.body.classList.add(classnames('search-opening'));
      }
    }
  };

  const isSuggestionOpening = (suggestionResults: HTMLElement) => {
    return !suggestionResults.classList.contains(classnames('g-hide'));
  };

  const onKeyboardNavigation = (
    input: HTMLInputElement,
    suggestionResults: HTMLElement,
    e: KeyboardEvent
  ) => {
    if (!suggestionResults) return;
    if (!isSuggestionOpening(suggestionResults) || !e || !e.key) return;
    const suggestionItems = suggestionResults.querySelectorAll(`.${suggestionTermCls.elm('item')}`);

    if (!suggestionItems || suggestionItems.length === 0) return;
    const viewAllProducts = suggestionResults.querySelector('[data-group="view-all-products"]');
    const numberOfItems = suggestionItems.length + (viewAllProducts ? 1 : 0);
    let isStopPropagation = false;

    if (e.key.includes('Down') || e.key.includes('Up')) {
      isStopPropagation = true;

      if (e.key.includes('Up')) {
        keyboardNavIndex--;
        if (keyboardNavIndex < -1) {
          keyboardNavIndex = numberOfItems - 1;
        }
      } else if (e.key.includes('Down')) {
        keyboardNavIndex++;
        if (keyboardNavIndex > numberOfItems - 1) {
          keyboardNavIndex = -1;
        }
      }
    }

    input.focus();

    if (keyboardNavIndex === -1) {
      // select input
      if (currentSearch) {
        input.value = currentSearch;
      }
      removeCurrentSelected(input, suggestionResults);
    } else if (viewAllProducts && keyboardNavIndex > suggestionItems.length - 1) {
      // view all
      if (currentSearch) {
        input.value = currentSearch;
      }
      removeCurrentSelected(input, suggestionResults);
      viewAllProducts?.classList.add(suggestionTermCls.elm('item.selected'));
      viewAllProducts.setAttribute('aria-selected', 'true');
    } else {
      removeCurrentSelected(input, suggestionResults);
      suggestionItems.forEach((item: Element, index: number) => {
        if (keyboardNavIndex === index) {
          addItemSelected(item, input);
        }
      });
    }

    if (e.key.includes('Left') || e.key.includes('Right')) {
      if (keyboardNavIndex !== -1) {
        isStopPropagation = true;
      }
    }

    if (isStopPropagation) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const addItemSelected = (item: Element, input: HTMLInputElement) => {
    const idActivedescendantFocus = suggestionTermCls.elm('item.selected');

    const textValue = item.getAttribute('data-title');
    input.value = textValue || '';
    input.setAttribute('aria-activedescendant', idActivedescendantFocus);
    item.id = idActivedescendantFocus;
    item.classList.add(suggestionTermCls.elm('item.selected'));
    item.setAttribute('aria-selected', 'true');
  };

  const removeCurrentSelected = (input: HTMLInputElement, suggestionResults: HTMLElement) => {
    input.removeAttribute('aria-activedescendant');

    const itemSelected = suggestionResults.querySelector(
      `.${suggestionTermCls.elm('item.selected')}, [data-group="view-all-products"]`
    );

    if (itemSelected) {
      itemSelected.removeAttribute('id');
      itemSelected.classList.remove(suggestionTermCls.elm('item.selected'));
      itemSelected.setAttribute('aria-selected', 'false');
    }
  };

  const calcMaxHeightByStyle = (maxHeight: number) => {
    let _maxHeight = maxHeight;

    if (isStyleFullWidth()) {
      return maxHeight;
    }

    // case current maxHeight >= maxHeightSettings -> chose maxHeightSettings
    if (suggestionMaxHeight && suggestionMaxHeight > 0 && _maxHeight >= suggestionMaxHeight) {
      return suggestionMaxHeight;
    }

    // !suggestionMaxHeight or maxHeight < suggestionMaxHeight
    if (!isMobile) {
      if (suggestionStyle === 'style2' && suggestionStyle2ProductItemType === 'list') {
        // 564px
        _maxHeight = Math.min(_maxHeight, 564);
      } else {
        // 640px
        _maxHeight = Math.min(_maxHeight, 640);
      }
    } else {
      if (suggestionMobileStyle === 'style2') {
        _maxHeight = Math.min(_maxHeight, 640);
      }
    }

    return _maxHeight;
  };

  const calcPositionSuggestionResult = (
    inputId: HTMLInputElement,
    suggestionResults: HTMLElement
  ) => {
    const rect = inputId.getBoundingClientRect();
    const position = 'absolute';

    // + 2 px => border of input
    // + 4 px: distance from input from input autocomplete to this input
    // window.scrollY: if window has scroll Y
    const scroll = window.scrollY;
    let top = rect.height + rect.top + 2 + 4;
    // - 20: distance from suggestion to bottom of window
    const _maxHeight = window.innerHeight - top - (isStyleFullWidth() ? 0 : 20);

    let _positionLeftOrRight = `left: ${rect.left}px`;

    // auto detect suggestion position
    if (window.innerWidth / 2 < rect.left) {
      _positionLeftOrRight = `right: ${window.innerWidth - rect.right}px`;
    }

    top = top + scroll;

    suggestionResults.setAttribute(
      'style',
      `position: ${position}; top: ${top}px; ${_positionLeftOrRight}; z-index: 999999998;`
    );

    const debounceScrollPage = debounce(() => {
      const header = document.getElementsByTagName('header');
      const searchForm = document.getElementsByClassName('boost-sd__search-form');
      let _zIndex;

      if (
        header.length > 0 &&
        window.scrollY > 0 &&
        searchForm[0]?.getBoundingClientRect().bottom <=
          header[0].getBoundingClientRect().top + header[0].clientHeight
      ) {
        _zIndex = 2;
      } else {
        _zIndex = 999999998;
      }

      suggestionResults.setAttribute(
        'style',
        `position: ${position}; top: ${top}px; ${_positionLeftOrRight}; z-index: ${_zIndex};`
      );
    }, 10);

    window.addEventListener('scroll', debounceScrollPage);

    const thisResults = suggestionResults.querySelector(`.${autocompleteCls.elm('results')}`);
    if (thisResults && !isStyleFullWidth()) {
      thisResults.setAttribute('style', `max-height: ${calcMaxHeightByStyle(_maxHeight)}px`);
    }
  };

  const preventAllEvents = (e: KeyboardEvent | Event) => {
    // prevent all event
    e.stopImmediatePropagation();
    e.stopPropagation();
    e.preventDefault();
  };

  const isXSSSearchTerm = (e: Event, searchTerm: string) => {
    // Returns on xss search term
    const newTerm = stripHtml(searchTerm);
    if (isBadSearchTerm(newTerm)) {
      if (e && typeof e.stopPropagation == 'function') {
        preventAllEvents(e);
      }
      return true;
    }
    return false;
  };

  const selectItemCurrentSelected = (suggestionResults: HTMLElement) => {
    if (!isSuggestionOpening(suggestionResults)) return;

    const selectElement = suggestionResults.querySelector(
      `.${suggestionTermCls.elm('item.selected')} > a`
    );
    if (selectElement) {
      const link = selectElement.getAttribute('href');
      if (link && !isBadUrl(link)) {
        return link;
      }
    }

    return false;
  };

  const onOpenSearchBarFullWidth = () => {
    const searchBarWrapper = document.getElementsByClassName(searchBarCls.elm('wrapper'))[0];
    if (searchBarWrapper) {
      // remove some theme have overlay, can't override or click outside not close search modal -> trigger click close this button.
      // Prestige , Supper store, Venture, District (in order)
      const closeSearchThemes = document.querySelectorAll(
        `#Search .Search__Close,
        .live-search-takeover-cancel,
        .js-drawer-open .page-container,
        .menu-drawer__modal-close.modal__close`.concat(
          `${closeSearchThemeSelector ? `, ${closeSearchThemeSelector}` : ''}`
        )
      ) as NodeListOf<HTMLButtonElement>;

      if (closeSearchThemes.length > 0) {
        closeSearchThemes.forEach((closeSearchTheme) => closeSearchTheme.click());
      }

      searchBarWrapper.classList.remove(classnames('g-hide'));
      document.body.classList.add(classnames('search-opening'));

      const searchBarInput = searchBarWrapper.querySelector(
        `.${searchBarIds.elm('input')}`
      ) as HTMLInputElement;

      if (searchBarInput) {
        if (boostThemeLib === 'focal' || isFocalTheme) {
          /**
           * ISSUE: Infinite uncaught errors - Issue occurred after fixing PFSN-43210
           * TARGET: focal theme
           */
          setTimeout(() => searchBarInput.focus(), 200);
        } else {
          searchBarInput.focus();
        }
        document.body.classList.add(classnames('g-no-scroll'));
      }
    }
  };

  const onCloseSuggestion = (suggestionResults: HTMLElement, input: HTMLInputElement) => {
    if (isSuggestionOpening(suggestionResults)) {
      suggestionResults.classList.add(classnames('g-hide'));
      document.body.classList.remove(classnames('search-opening'));

      setCurrentSearchContentResultActive('');

      input.setAttribute('aria-expanded', 'false');
    }
  };

  const onOpenSuggestion = (suggestionResults: HTMLElement, input: HTMLInputElement) => {
    if (!isSuggestionOpening(suggestionResults)) {
      setCurrentSearchContentResultActive(idSuggestionResults);
      suggestionResults.classList.remove(classnames('g-hide'));

      if (!input.classList.contains(searchFormCls.elm('input'))) {
        document.body.classList.add(classnames('search-opening'));
      }

      input.setAttribute('aria-expanded', 'true');
    }
  };

  const isStyleFullWidth = () => {
    return (
      (isMobile && suggestionMobileStyle === 'style1') ||
      (!isMobile && suggestionStyle === 'style3')
    );
  };

  return <></>;
};

export default registryComponent('SearchInput', SearchInput);
