import './SearchContentResult.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import CollectionResult from '@components/CollectionResult';
import NoSearchResult from '@components/NoSearchResult';
import PageBlogResult from '@components/PageBlogResult';
import SearchPlaceHolder from '@components/SearchPlaceholder';
import SearchProducts from '@components/SearchProducts';
import SpellingCorrection from '@components/SpellingCorrection';
import SuggestionQueries from '@components/SuggestionQueries';
import ViewAll from '@components/ViewAll';
import { getOnClickRecentSearches } from '@constants/instantSearchClick';
import useTranslation from '@hooks/useTranslation';
import type { SearchBoxOnClickBlock } from '@providers/SearchProvider';
import { useSearchSettings, useSearchState } from '@providers/SearchProvider';
import type { Dict } from '@types';
import { generateSearchPageHref, isBadSearchTerm, isMobileWidth, stripHtml } from '@utils';
import classnames, { createClsNameMap, mapModifiers } from '@utils/classnames';

import DesktopStyle1 from './DesktopStyle1';
import DesktopStyle2 from './DesktopStyle2';
import DesktopStyle3 from './DesktopStyle3';
import MobileStyle1 from './MobileStyle1';
import MobileStyle2 from './MobileStyle2';

export const clsNameMap = createClsNameMap({
  elements: {
    autocomplete: createClsNameMap(),
    results: createClsNameMap({
      modifiers: ['group'],
    }),
    'no-product': createClsNameMap(),
    'no-other': createClsNameMap(),
    disabled: createClsNameMap({
      modifiers: ['left', 'right'],
    }),
    column: createClsNameMap({
      modifiers: ['others', 'products'],
    }),
    btn: createClsNameMap(),
    loading: createClsNameMap(),
    group: createClsNameMap(),
  },
})('instant-search');

export type SearchContentResultProps = {
  id: string;
};

export type SuggestionBlockType = {
  type: string;
  label: string;
  status: 'active' | 'disabled';
  number: number;
};

const SearchContentResult = ({ id }: SearchContentResultProps) => {
  const { t } = useTranslation();

  const {
    settings: {
      suggestionStyle, // style of desktop
      suggestionMobileStyle, // style of mobile
      suggestionMobileProductItemType, // list, grid on mobile
      suggestionStyle1ProductItemType, // list, grid
      suggestionStyle1ProductPosition, // Always 'none'
      suggestionStyle2ProductItemType, // list, grid
      suggestionStyle2ProductPosition, // left, right'
      suggestionStyle2ProductPerRow, //
      suggestionStyle3ProductItemType, // list, grid
      suggestionStyle3ProductPosition, // left, right
      suggestionStyle3ProductPerRow, //
      suggestionBlocks,
      searchBoxOnclick,
      suggestionNoResult,
      highlightSuggestionResult,
    },
  } = useSearchSettings();

  const {
    searchResult,
    searchTerm,
    trendingProducts,
    isLoading,
    mostProducts,
    mostSearchTerms,
    currentSearchContentResultActive,
    searchRedirects,
  } = useSearchState();

  const hasDidYouMean = searchResult?.did_you_mean?.length > 0;
  const didYouMean = searchResult?.did_you_mean;
  const scopedSuggestions = searchResult?.scoped_suggestions;

  const blockOthersHaveResultActive = suggestionBlocks.filter(
    (block: SuggestionBlockType) => block['status'] === 'active' && block['type'] !== 'products'
  );

  const blockProductsHaveResultActive = suggestionBlocks.filter(
    (block: SuggestionBlockType) => block['status'] === 'active' && block['type'] === 'products'
  );

  const blockHaveResultActive = suggestionBlocks.filter(
    (block: SuggestionBlockType) => block['status'] === 'active'
  );

  // all style
  const isDesktopStyle1 = suggestionStyle === 'style1' && !isMobileWidth();
  const isDesktopStyle2 = suggestionStyle === 'style2' && !isMobileWidth();
  const isDesktopStyle3 = suggestionStyle === 'style3' && !isMobileWidth();
  const isMobileStyle1 = suggestionMobileStyle === 'style1' && isMobileWidth();
  const isMobileStyle2 = suggestionMobileStyle === 'style2' && isMobileWidth();

  const buildClassByStyle = () => {
    let suggestionClasses = '';
    const suggestionPrefix = clsNameMap.root();

    if (isMobileWidth()) {
      // add style mobile
      suggestionClasses += `${suggestionPrefix}-results--mobile`;

      // add style mobile detail style: style2 | style1
      suggestionClasses += ` ${suggestionPrefix}-results--mobile-${suggestionMobileStyle}`;
      suggestionClasses += ` ${suggestionPrefix}-results--product-type-${suggestionMobileProductItemType}`;
    } else {
      // style for desktop
      switch (suggestionStyle) {
        case 'style1':
          suggestionClasses += `${suggestionPrefix}-results--one-column`;
          suggestionClasses += ` ${suggestionPrefix}-results--product-position-${suggestionStyle1ProductPosition}`;
          suggestionClasses += ` ${suggestionPrefix}-results--product-type-${suggestionStyle1ProductItemType}`;
          break;
        case 'style2':
          suggestionClasses += ` ${suggestionPrefix}-results--two-column`;
          suggestionClasses += ` ${suggestionPrefix}-results--product-position-${suggestionStyle2ProductPosition}`;
          suggestionClasses += ` ${suggestionPrefix}-results--product-per-row-${suggestionStyle2ProductPerRow}`;
          suggestionClasses += ` ${suggestionPrefix}-results--product-type-${suggestionStyle2ProductItemType}`;
          break;
        case 'style3':
          suggestionClasses += ` ${suggestionPrefix}-results--full-width`;
          suggestionClasses += ` ${suggestionPrefix}-results--product-position-${suggestionStyle3ProductPosition}`;
          suggestionClasses += ` ${suggestionPrefix}-results--product-per-row-${suggestionStyle3ProductPerRow}`;
          suggestionClasses += ` ${suggestionPrefix}-results--product-type-${suggestionStyle3ProductItemType}`;
          break;
        default:
          suggestionClasses += ` ${suggestionPrefix}-results--two-column`;
          suggestionClasses += ` ${suggestionPrefix}-results--product-position-right`;
          suggestionClasses += ` ${suggestionPrefix}-results--product-per-row-2`;
          suggestionClasses += ` ${suggestionPrefix}-results--product-type-list`;
      }
    }

    return suggestionClasses;
  };

  const ContentSearchBoxOnclick = () => {
    const _blocksOtherActive = () => {
      return (
        searchBoxOnclick['recentSearch'].status || searchBoxOnclick['searchTermSuggestion'].status
      );
    };

    const _blockProductActive = () => {
      return searchBoxOnclick['productSuggestion'].status;
    };

    const RenderBlock = ({ type }: { type: SearchBoxOnClickBlock }) => {
      switch (type) {
        case 'recentSearch':
          return (
            <SuggestionQueries
              query={searchTerm}
              suggestions={getOnClickRecentSearches(searchBoxOnclick.recentSearch.number)}
              suggestionsRedirect={searchRedirects}
              headerTitle={t('suggestion.searchBoxOnclickRecentSearchLabel')}
              type='recent'
              isMobileStyle1={isMobileWidth() && isMobileStyle1}
              isHightLight={highlightSuggestionResult}
            />
          );

        case 'searchTermSuggestion':
          return (
            <SuggestionQueries
              query={searchTerm}
              suggestions={searchBoxOnclick.searchTermSuggestion.data}
              suggestionsRedirect={searchRedirects}
              scopedSuggestions={scopedSuggestions}
              headerTitle={t('suggestion.searchBoxOnclickSearchTermLabel')}
              type='popular'
              isMobileStyle1={isMobileWidth() && isMobileStyle1}
              isHightLight={highlightSuggestionResult}
            />
          );

        case 'productSuggestion':
          return (
            <SearchProducts
              products={trendingProducts}
              headerTitle={t('suggestion.searchBoxOnclickProductsLabel')}
              type='trending'
            />
          );

        default:
          return <></>;
      }
    };

    const RenderOthersBlock = () => {
      if (!_blocksOtherActive()) return <></>;

      return (
        <>
          {Object.keys(searchBoxOnclick)
            ?.filter(
              (key) =>
                key !== 'productSuggestion' && searchBoxOnclick[key as SearchBoxOnClickBlock].status
            )
            ?.map((_key) => (
              <RenderBlock key={_key} type={_key as SearchBoxOnClickBlock} />
            ))}
        </>
      );
    };

    const RenderProductsBlock = () => {
      if (!_blockProductActive()) return <></>;
      return <RenderBlock type={'productSuggestion'} />;
    };

    const ContentSearchBoxOnClickDefaultDesktop = () => {
      return (
        <>
          <div
            className={`${mapModifiers(clsNameMap.column, { others: true })} ${classnames(
              'g-scrollable'
            )}`}
          >
            <RenderOthersBlock />
          </div>
          <div
            className={`${mapModifiers(clsNameMap.column, { products: true })} ${classnames(
              'g-scrollable'
            )}`}
          >
            <RenderProductsBlock />
          </div>
        </>
      );
    };

    const ContentSearchBoxOnclickDefaultMobile = () => {
      return (
        <>
          {Object.keys(searchBoxOnclick)
            ?.filter((key) => searchBoxOnclick[key as SearchBoxOnClickBlock].status)
            ?.map((_key) => (
              <RenderBlock key={_key} type={_key as SearchBoxOnClickBlock} />
            ))}
        </>
      );
    };

    switch (true) {
      case isDesktopStyle1:
        return (
          <DesktopStyle1>
            {/* Because desktop 1 column the same structure of mobile  */}
            <ContentSearchBoxOnclickDefaultMobile />
          </DesktopStyle1>
        );
      case isDesktopStyle2:
        return (
          <DesktopStyle2>
            <ContentSearchBoxOnClickDefaultDesktop />
          </DesktopStyle2>
        );
      case isDesktopStyle3:
        return (
          <DesktopStyle3>
            <ContentSearchBoxOnClickDefaultDesktop />
          </DesktopStyle3>
        );
      case isMobileStyle1:
        return (
          <MobileStyle1>
            <ContentSearchBoxOnclickDefaultMobile />
          </MobileStyle1>
        );
      case isMobileStyle2:
        return (
          <MobileStyle2>
            <ContentSearchBoxOnclickDefaultMobile />
          </MobileStyle2>
        );
      default:
        return <></>;
    }
  };

  const isNoResults = () => {
    return searchResult?.all_empty && !hasDidYouMean;
  };

  const NoSearchResultView = () => {
    if (!isNoResults()) return <></>;

    const noSuggestionResult =
      !suggestionNoResult?.products?.status && !suggestionNoResult?.search_terms?.status;

    // disable all settings and have product -> not show no search result
    if (noSuggestionResult && searchResult?.total_product > 0) return null;

    return (
      <NoSearchResult
        searchTerm={stripHtml(searchTerm)}
        keyTranslationTerm={
          noSuggestionResult ? 'error.noSuggestionResult' : 'search.resultEmptyWithSuggestion'
        }
        havePaddingBottom={noSuggestionResult}
      />
    );
  };

  const SearchContentNoResult = () => {
    // because all empty => noProduct & noOther
    const isShowMostSearchTerms = () => {
      return suggestionNoResult.search_terms.status;
    };

    const ContentDefaultMobile = () => {
      return (
        <>
          <NoSearchResultView />
          {isShowMostSearchTerms() && (
            <SuggestionQueries
              query={searchTerm}
              suggestions={mostSearchTerms}
              suggestionsRedirect={searchRedirects}
              headerTitle={t('suggestion.noSearchResultSearchTermLabel')}
              type={'most'}
              isMobileStyle1={isMobileWidth() && isMobileStyle1}
              isHightLight={highlightSuggestionResult}
            />
          )}
          <SearchProducts
            products={mostProducts}
            headerTitle={t('suggestion.noSearchResultProductsLabel')}
            type='most'
          />
        </>
      );
    };

    const ContentDefaultDesktop = () => {
      const isShowSuggestionBlock = isShowMostSearchTerms() && mostSearchTerms?.length;
      const isShowProductBlock = mostProducts?.length;

      const SuggestionBlock = () => {
        if (!isShowSuggestionBlock) return <></>;

        return (
          <div
            className={`${mapModifiers(clsNameMap.column, { others: true })} ${classnames(
              'g-scrollable'
            )}`}
          >
            <SuggestionQueries
              query={searchTerm}
              suggestions={mostSearchTerms}
              headerTitle={t('suggestion.noSearchResultSearchTermLabel')}
              type='most'
              isHightLight={highlightSuggestionResult}
            />
          </div>
        );
      };

      const ProductBlock = () => {
        if (!isShowProductBlock) return <></>;

        return (
          <div
            className={`${mapModifiers(clsNameMap.column, { products: true })} ${classnames(
              'g-scrollable'
            )}`}
          >
            <div className={`${clsNameMap.elm('group')} ${classnames(...['g-scrollable'])}`}>
              <NoSearchResultView />

              <SearchProducts
                products={mostProducts}
                headerTitle={t('suggestion.noSearchResultProductsLabel')}
                type='most'
              />
            </div>
          </div>
        );
      };

      if (!isShowSuggestionBlock && !isShowProductBlock) return <NoSearchResultView />;

      return (
        <>
          <SuggestionBlock />
          <ProductBlock />
        </>
      );
    };

    switch (true) {
      case isMobileStyle1:
        return (
          <MobileStyle1>
            <ContentDefaultMobile />
          </MobileStyle1>
        );
      case isMobileStyle2:
        return (
          <MobileStyle2>
            <ContentDefaultMobile />
          </MobileStyle2>
        );
      case isDesktopStyle1:
        return (
          <DesktopStyle1>
            {/* Because structure of desktop style 1 column the same mobile and difference desktop2,3 */}
            <ContentDefaultMobile />
          </DesktopStyle1>
        );
      case isDesktopStyle2:
        return (
          <DesktopStyle2>
            <ContentDefaultDesktop />
          </DesktopStyle2>
        );
      case isDesktopStyle3:
        return (
          <DesktopStyle3>
            <ContentDefaultDesktop />
          </DesktopStyle3>
        );
      default:
        return <></>;
    }
  };

  const SearchContentResultByStyle = ({
    isNoProduct,
    isNoOther,
  }: {
    isNoProduct: boolean;
    isNoOther: boolean;
  }) => {
    const ViewAllResult = () => {
      const blockProducts = suggestionBlocks.find((block) => block.type === 'products');

      if (!blockProducts || blockProducts?.status === 'disabled' || blockProducts?.number <= 0)
        return null;

      return (
        <ViewAll
          isShow={
            searchTerm &&
            searchResult &&
            searchResult.products &&
            searchResult.products.length > 0 &&
            searchResult.total_product > blockProducts?.number
          }
          textContent={t('suggestion.viewAll', { count: searchResult.total_product })}
          link={generateSearchPageHref(stripHtml(searchTerm))}
        />
      );
    };

    const isShowMostSearchTerms = () => {
      return isNoResults() && suggestionNoResult.search_terms.status;
    };

    const RenderBlock = ({ type }: { type: string }) => {
      switch (type) {
        case 'suggestions':
          return (
            <SuggestionQueries
              query={searchTerm}
              suggestions={searchResult?.suggestions}
              suggestionsRedirect={searchRedirects}
              scopedSuggestions={scopedSuggestions}
              headerTitle={t('suggestion.instantSearchSuggestionsLabel')}
              type='suggestion'
              isMobileStyle1={isMobileWidth() && isMobileStyle1}
              isHightLight={highlightSuggestionResult}
            />
          );

        case 'collections':
          return (
            <CollectionResult
              list={searchResult?.collections}
              query={searchTerm}
              headerTitle={t('suggestion.instantSearchCollectionsLabel')}
              type='collection'
              isMobileStyle1={isMobileWidth() && isMobileStyle1}
              isHighlight={highlightSuggestionResult}
            />
          );

        case 'pages':
          return (
            <PageBlogResult
              query={searchTerm}
              list={searchResult?.pages}
              headerTitle={t('suggestion.instantSearchPagesLabel')}
              type='page'
              isMobileStyle1={isMobileWidth() && isMobileStyle1}
              isHighlight={highlightSuggestionResult}
            />
          );

        case 'products':
          return (
            <>
              <SearchProducts
                products={searchResult.products}
                headerTitle={t('suggestion.instantSearchProductsLabel')}
                type='product'
              />
            </>
          );

        default:
          return <></>;
      }
    };

    const RenderOthersBlock = () => {
      if (!blockOthersHaveResultActive || blockOthersHaveResultActive.length === 0) return <></>;

      return (
        <>
          {blockOthersHaveResultActive.map((block: Dict) => (
            <RenderBlock key={block.type} type={block.type} />
          ))}
        </>
      );
    };

    const RenderBlockWithOrder = () => {
      if (!blockHaveResultActive || blockHaveResultActive.length === 0) return <></>;
      return (
        <>
          {blockHaveResultActive.map((block: Dict) => (
            <RenderBlock key={block.type} type={block.type} />
          ))}
        </>
      );
    };

    const RenderProductsBlock = () => {
      if (!blockProductsHaveResultActive || blockProductsHaveResultActive.length === 0) {
        return <></>;
      }

      return <RenderBlock type={'products'} />;
    };

    switch (true) {
      case isDesktopStyle1:
        return (
          <DesktopStyle1>
            <>
              <div className={`${clsNameMap.elm('group')} ${classnames(...['g-scrollable'])}`}>
                {isNoResults() && <NoSearchResultView />}
                {isShowMostSearchTerms() && (
                  <SuggestionQueries
                    query={searchTerm}
                    suggestions={mostSearchTerms}
                    headerTitle={t('suggestion.noSearchResultSearchTermLabel')}
                    type='most'
                    isHightLight={highlightSuggestionResult}
                  />
                )}
                {hasDidYouMean && <SpellingCorrection correctQueries={didYouMean} />}

                <RenderBlockWithOrder />

                {isNoResults() && (
                  <SearchProducts
                    products={mostProducts}
                    headerTitle={t('suggestion.instantSearchProductsLabel')}
                    type='most'
                  />
                )}
              </div>
              <ViewAllResult />
            </>
          </DesktopStyle1>
        );
      case isDesktopStyle2:
        return (
          <DesktopStyle2>
            <>
              <div
                className={`${mapModifiers(clsNameMap.column, { others: true })} ${classnames(
                  'g-scrollable'
                )}`}
              >
                {isShowMostSearchTerms() && (
                  <SuggestionQueries
                    query={searchTerm}
                    suggestions={mostSearchTerms}
                    headerTitle={t('suggestion.noSearchResultSearchTermLabel')}
                    type='most'
                    isHightLight={highlightSuggestionResult}
                  />
                )}
                {!isNoOther && <RenderOthersBlock />}
              </div>
              <div
                className={`${mapModifiers(clsNameMap.column, { products: true })} ${classnames(
                  'g-scrollable'
                )}`}
              >
                <div className={`${clsNameMap.elm('group')} ${classnames(...['g-scrollable'])}`}>
                  {isNoResults() && <NoSearchResultView />}

                  {hasDidYouMean && <SpellingCorrection correctQueries={didYouMean} />}
                  {isNoResults() && (
                    <SearchProducts
                      products={mostProducts}
                      headerTitle={t('suggestion.noSearchResultProductsLabel')}
                      type='most'
                    />
                  )}
                  {!isNoProduct && <RenderProductsBlock />}
                </div>
                <ViewAllResult />
              </div>
            </>
          </DesktopStyle2>
        );
      case isDesktopStyle3:
        return (
          <DesktopStyle3>
            <>
              <div
                className={`${mapModifiers(clsNameMap.column, { others: true })} ${classnames(
                  'g-scrollable'
                )}`}
              >
                {isShowMostSearchTerms() && (
                  <SuggestionQueries
                    query={searchTerm}
                    suggestions={mostSearchTerms}
                    headerTitle={t('suggestion.noSearchResultSearchTermLabel')}
                    type='most'
                    isHightLight={highlightSuggestionResult}
                  />
                )}
                {!isNoOther && <RenderOthersBlock />}
              </div>
              <div
                className={`${mapModifiers(clsNameMap.column, { products: true })} ${classnames(
                  'g-scrollable'
                )}`}
              >
                <div className={`${clsNameMap.elm('group')} ${classnames(...['g-scrollable'])}`}>
                  {isNoResults() && <NoSearchResultView />}
                  {hasDidYouMean && <SpellingCorrection correctQueries={didYouMean} />}
                  {isNoResults() && (
                    <SearchProducts
                      products={mostProducts}
                      headerTitle={t('suggestion.noSearchResultProductsLabel')}
                      type='most'
                    />
                  )}
                  {!isNoProduct && <RenderProductsBlock />}
                </div>
                <ViewAllResult />
              </div>
            </>
          </DesktopStyle3>
        );
      case isMobileStyle1:
        return (
          <MobileStyle1>
            <>
              <div
                className={`${mapModifiers(clsNameMap.column, { others: true })} ${classnames(
                  'g-scrollable'
                )}`}
              >
                {isNoResults() && <NoSearchResultView />}
                {isShowMostSearchTerms() && (
                  <SuggestionQueries
                    query={searchTerm}
                    suggestions={mostSearchTerms}
                    headerTitle={t('suggestion.noSearchResultSearchTermLabel')}
                    type={'most'}
                    isMobileStyle1={isMobileWidth() && isMobileStyle1}
                    isHightLight={highlightSuggestionResult}
                  />
                )}

                {hasDidYouMean && <SpellingCorrection correctQueries={didYouMean} />}

                <RenderBlockWithOrder />

                {isNoResults() && (
                  <SearchProducts
                    products={mostProducts}
                    headerTitle={t('suggestion.noSearchResultProductsLabel')}
                    type='most'
                  />
                )}
              </div>
              <ViewAllResult />
            </>
          </MobileStyle1>
        );
      case isMobileStyle2:
        return (
          <MobileStyle2>
            <>
              <div
                className={`${mapModifiers(clsNameMap.column, { others: true })} ${classnames(
                  'g-scrollable'
                )}`}
              >
                {isNoResults() && <NoSearchResultView />}
                {isShowMostSearchTerms() && (
                  <SuggestionQueries
                    suggestions={mostSearchTerms}
                    query={searchTerm}
                    headerTitle={t('suggestion.noSearchResultSearchTermLabel')}
                    type='most'
                    isHightLight={highlightSuggestionResult}
                  />
                )}

                {hasDidYouMean && <SpellingCorrection correctQueries={didYouMean} />}
                <RenderBlockWithOrder />

                {isNoResults() && (
                  <SearchProducts
                    products={mostProducts}
                    headerTitle={suggestionNoResult.products.label}
                    type='most'
                  />
                )}
              </div>
              <ViewAllResult />
            </>
          </MobileStyle2>
        );
      default:
        return null;
    }
  };

  const SearchContentResult = () => {
    // Please DON'T CHANGE position of 1,2,3,4

    // 1. when user focus input the first -> searchBoxOnclick should be show
    if (!searchTerm) {
      const { recentSearch, productSuggestion, searchTermSuggestion } = searchBoxOnclick;
      const _noProduct = productSuggestion?.data?.length === 0 || trendingProducts?.length === 0;

      const _noOther =
        (!recentSearch.status ||
          getOnClickRecentSearches(searchBoxOnclick.recentSearch.number).length === 0) &&
        (!searchTermSuggestion.status || searchTermSuggestion.data?.length === 0);
      return (
        <div
          className={`${clsNameMap.elm('autocomplete')} ${
            _noProduct ? clsNameMap.elm('no-product') : ''
          } ${_noOther ? clsNameMap.elm('no-other') : ''}`}
        >
          <ContentSearchBoxOnclick />
        </div>
      );
    }

    // 2. when user typed and searching -> Placeholder search should be show
    if (isLoading) {
      return (
        <div className={clsNameMap.elm('autocomplete')}>
          <SearchPlaceHolder className={clsNameMap.elm('loading')} />
        </div>
      );
    }

    // 3. when have result but all is empty -> SearchContentNoResult should be show
    if (searchResult.all_empty) {
      const _noProduct = !suggestionNoResult.products.status;
      const _noOther = !suggestionNoResult.search_terms.status;

      return (
        <div
          className={`${clsNameMap.elm('autocomplete')} ${
            _noProduct ? clsNameMap.elm('no-product') : ''
          } ${_noOther ? clsNameMap.elm('no-other') : ''}`}
        >
          <SearchContentNoResult />
        </div>
      );
    }

    // 3. when have result and have data return -> is not empty -> SearchContentHaveResult should be show
    const _noOther =
      blockOthersHaveResultActive?.filter((block: Dict) => searchResult[block.type]?.length > 0)
        ?.length === 0;
    const _noProduct =
      blockProductsHaveResultActive.length === 0 || searchResult.total_product === 0;
    return (
      <div
        className={`${clsNameMap.elm('autocomplete')} ${
          _noProduct ? clsNameMap.elm('no-product') : ''
        } ${_noOther ? clsNameMap.elm('no-other') : ''} ${classnames('g-flexColumn')}`}
      >
        <SearchContentResultByStyle isNoProduct={_noProduct} isNoOther={_noOther} />
      </div>
    );
  };

  ///// RENDER SEARCH CONTENT RESULT /////
  return (
    <div
      className={`${mapModifiers(clsNameMap.results, {
        group: true,
      })} ${buildClassByStyle()}`}
    >
      {id !== currentSearchContentResultActive || isBadSearchTerm(stripHtml(searchTerm)) ? (
        <></>
      ) : (
        <SearchContentResult />
      )}
    </div>
  );
};

export default registryComponent('SearchContentResult', SearchContentResult);
