import { InnerHTMLRenderer } from '@components/InnerHTMLRenderer';
import useTranslation from '@hooks/useTranslation';

export type TranslateWithComponentProps = {
  translation: string;
  data?: Record<string, unknown>;
  style?: Record<string, unknown>;
  className?: string;
};

const TranslateWithComponent = ({
  translation,
  data,
  style,
  className,
}: TranslateWithComponentProps) => {
  const { t } = useTranslation();

  return (
    <InnerHTMLRenderer
      as='span'
      className={className}
      style={style}
      html={t(translation, {
        ...data,
        interpolation: { escapeValue: false },
      })}
    />
  );
};

export default TranslateWithComponent;
