import './SearchBar.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import ButtonTextX from '@components/ButtonTextX';
import useTranslation from '@hooks/useTranslation';
import { useSearchSettings, useSearchState } from '@providers/SearchProvider';
import { buildUrlWithLocalization, isMobileWidth } from '@utils';
import classnames, { createClsNameMap, mapModifiers } from '@utils/classnames';
import { useRef } from 'react';

import SearchContentResult from '@/widgets/SWSearchContentResult';

export const clsNameMap = createClsNameMap({
  elements: {
    wrapper: createClsNameMap(),
    submit: createClsNameMap({
      elements: {
        style3: createClsNameMap({
          modifiers: ['show'],
        }),
      },
      modifiers: ['style3'],
    }),
    input: createClsNameMap(),
    hide: createClsNameMap(),
    icon: createClsNameMap(),
    autocomplete: createClsNameMap(),
  },
})('search-bar');

export const idsNameMap = createClsNameMap({
  elements: {
    autocomplete: createClsNameMap({
      elements: {
        wrapper: createClsNameMap(),
      },
    }),
    input: createClsNameMap(),
  },
})('search-bar');

const SearchBar = () => {
  const { t } = useTranslation();

  const { searchTerm, setSearchTerm } = useSearchState();
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const {
    settings: { suggestionStyle, showSearchBtnStyle3, suggestionMobileStyle },
  } = useSearchSettings();

  const searchBarHide = () => {
    const hide = () => {
      const searchBarWrapper = document.getElementsByClassName(clsNameMap.elm('wrapper'))[0];
      searchBarWrapper && searchBarWrapper.classList.add(classnames('g-hide'));
      document.body.classList.remove(classnames('g-no-scroll'));
      document.body.classList.remove(classnames('search-opening'));
    };

    if (document.body.classList.contains('show-search')) {
      // close ISW full-width - Symmetry Theme
      document.body.classList.remove('show-search');
      setTimeout(hide, 200);
      return;
    }

    hide();
  };

  const isSearchBtnStyle3Show = () => {
    return !isMobileWidth() && suggestionStyle === 'style3' && showSearchBtnStyle3;
  };

  const buildClasses = () => {
    let classesWrapper = '';
    const prefix = clsNameMap.elm('wrapper');
    if (isMobileWidth()) {
      classesWrapper += ` ${prefix}--mobile`;
      classesWrapper += ` ${prefix}--mobile-${suggestionMobileStyle}`;
    } else {
      classesWrapper += ` ${prefix}--desktop`;
      classesWrapper += ` ${prefix}--desktop-${suggestionStyle}`;
    }

    return classesWrapper;
  };

  const isDesktopStyle3 = () => {
    return !isMobileWidth() && suggestionStyle === 'style3';
  };

  return (
    <div className={`${clsNameMap.elm('wrapper')} ${classnames('g-hide')} ${buildClasses()}`}>
      <form action={buildUrlWithLocalization('search')} className={clsNameMap.root()}>
        {isSearchBtnStyle3Show() && (
          <button className={mapModifiers(clsNameMap.submit, { style3: true })} type='submit'>
            <span>Submit</span>
          </button>
        )}

        <ButtonTextX
          value='Close'
          modifiers={isDesktopStyle3() ? ['close-style3'] : ['close-style1']}
          onClick={() => searchBarHide()}
        />

        <input
          ref={inputRef}
          id={idsNameMap.elm('input')}
          className={`${clsNameMap.elm('input')} ${
            isSearchBtnStyle3Show() ? mapModifiers(clsNameMap.submit.style3, { show: true }) : ''
          }`}
          autoComplete='off'
          placeholder={t('suggestion.searchBoxPlaceholder')}
          name='q'
        />
        {inputRef?.current?.value && (
          <ButtonTextX
            value={'Clear'}
            modifiers={isDesktopStyle3() ? ['clear-style3'] : ['clear-style1']}
            className={searchTerm ? classnames('g-show') : classnames('g-hide')}
            onClick={() => {
              inputRef.current?.focus();
              inputRef.current.value = '';
              setSearchTerm('');
            }}
          />
        )}
      </form>
      <div id={idsNameMap.elm('autocomplete.wrapper')} className={clsNameMap.elm('autocomplete')}>
        <SearchContentResult id={idsNameMap.elm('autocomplete.wrapper')} />
      </div>
    </div>
  );
};

export default registryComponent('SearchBar', SearchBar);
