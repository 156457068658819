import './HeaderItem.scss';

import { appendClassNames, createClsNameMap } from '@utils';

export const clsNameMap = createClsNameMap({})('header-item');

export type HeaderItemProps = {
  className?: string;
  value: string;
};

const HeaderItem = ({ value, className }: HeaderItemProps) => {
  return <h3 className={appendClassNames(clsNameMap.root(), className)}>{value}</h3>;
};

export default HeaderItem;
