import './SWSearchWidgetInit.scss';

import { useForceRender, useMountEffect } from '@boost-sd/core-js';
import useGeneralSettings from '@hooks/useGeneralSettings';
import { BOOST_SD_SEARCH_RESULTS_CACHE } from '@hooks/useLocalStorage';
import { createClsNameMap, getLocalStorage, setLocalStorage } from '@utils';
import { useEffect } from 'react';

import SearchBar, { idsNameMap as searchBarIds } from '@/widgets/SWSearchBar';
import SearchInput from '@/widgets/SWSearchInput';

import SWSearchContentResult from '../SWSearchContentResult';

export const clsNameMap = createClsNameMap({
  elements: {
    wrapper: createClsNameMap({}),
    input: createClsNameMap({}),
    enabled: createClsNameMap({}),
  },
})('search-widget-init');

export type InstantSearchInitProps = {
  hasSearchModal?: boolean;
  inputSelector?: string;
};

const FLOW_THEME_SELECTOR = '.right-drawer-vue div';

const InstantSearchInit = ({ hasSearchModal = true, inputSelector }: InstantSearchInitProps) => {
  const {
    generalSettings: { termKey },
  } = useGeneralSettings();

  const forceRender = useForceRender();

  const registerInstantForInputs = (inputs: NodeListOf<Element>) => {
    inputs.forEach((input: Element, i) => {
      input.id = `${clsNameMap.elm('input')}-${i}`;
      input.classList.add(clsNameMap.elm('input'));
    });

    document.body.classList.add(clsNameMap.elm('enabled'));
  };

  useEffect(() => {
    // reset searchResultsCache each reload page
    const searchResultsCache = getLocalStorage(BOOST_SD_SEARCH_RESULTS_CACHE);
    if (searchResultsCache) {
      setLocalStorage(BOOST_SD_SEARCH_RESULTS_CACHE, {});
    }
  }, []);

  const getInputSelector = () => {
    const inputSelectors = [`[name="${termKey}"]`];
    if (inputSelector) {
      inputSelectors.push(inputSelector);
    }

    return inputSelectors
      .map((selector) => `input${selector}:not([data-disable-instant-search])`)
      .join(',');
  };

  const initMultiInstantSearch = () => {
    const inputs = document.querySelectorAll(getInputSelector());

    registerInstantForInputs(inputs);

    if (inputs.length === 0) return null;

    return Array.from(inputs).map((input, i) => {
      const thisSearchboxWrapper = `${clsNameMap.elm('wrapper')}-${i}`;

      /**
       * Key need to be unique to force <SearchInput> to re-create new instance instead of reuse old instance with same key
       * avoiding problem like can not open ISW search form
       */
      let key = thisSearchboxWrapper + input.className;

      if (document.querySelector(FLOW_THEME_SELECTOR)) {
        /**
         * Flow theme unmount/mount the search input DOM on open/close search drawer
         * the key needs to be even more unique for binding events can be registered on the newly created DOM
         */
        key += Date.now();
      }

      return (
        <div id={thisSearchboxWrapper} key={key} className={clsNameMap.elm('wrapper')}>
          <SearchInput
            id={input.id} // id input of theme in header
            idSuggestionResults={thisSearchboxWrapper}
            hasSearchModal={hasSearchModal}
          />
          <SWSearchContentResult id={thisSearchboxWrapper} />
        </div>
      );
    });
  };

  useMountEffect(function listenBindSearchInputEvent() {
    window.addEventListener('bind-search-input', forceRender);

    return () => {
      window.removeEventListener('bind-search-input', forceRender);
    };
  });

  useEffect(() => {
    /**
     * Flow theme is a special theme that removes the whole DOM Node of the search drawer whenever it is closed
     * we need to keep track of the toggle open/close and force re-render to bind the ISW input event on the newly created theme input
     */

    const element = document.querySelector(FLOW_THEME_SELECTOR);
    if (!element) return;

    let hasChildren = false;

    const config = { attributes: true, childList: true, subtree: true };

    const callback = (mutationList: any, observer: MutationObserver) => {
      const currHasChildren = element.children.length > 0;
      if (hasChildren !== currHasChildren) {
        if (currHasChildren) {
          // wait for search drawer to show
          setTimeout(forceRender, 300);
        }

        hasChildren = currHasChildren;
      }
    };

    const observer = new MutationObserver(callback);

    observer.observe(element, config);
  }, []);

  return (
    <>
      {/* Create input of boost  */}
      <SearchBar />

      {/* NOTE: Bind event to input of boost for all page  */}
      <SearchInput
        id={searchBarIds.elm('input')} // id input of boost
        idSuggestionResults={searchBarIds.elm('autocomplete.wrapper')} // id suggestion wrapper in SearchBar component
        isOwnerInput={true} // = true: if input of boost
      />

      {initMultiInstantSearch()}
    </>
  );
};

export default InstantSearchInit;
