import { InstantSearchInit, SearchProvider } from '@boost-sd/app';
import { createRoot } from 'react-dom/client';

import type { Block } from '../types';

export type InstantSearchBlock = Block<{
  containerId: string;
}>;

const defaultConfig = {
  containerId: 'boost-sd__instant-search-container',
};

export const createInstantSearchBlock = (
  config: InstantSearchBlock['config'] = defaultConfig
): InstantSearchBlock => {
  return {
    config: {
      ...defaultConfig,
      ...config,
    },
    render() {
      const { containerId } = {
        ...defaultConfig,
        ...config,
      };

      const instantSearchContainer = document.createElement('div');
      instantSearchContainer.setAttribute('id', containerId);
      document.body.appendChild(instantSearchContainer);

      const root = createRoot(instantSearchContainer);

      root.render(
        <SearchProvider>
          <InstantSearchInit />
        </SearchProvider>
      );
    },
  };
};
