import './SpellingCorrection.scss';

import useTranslation from '@hooks/useTranslation';
import { createClsNameMap, generateSearchPageHref, mapModifiers } from '@utils';

export const clsNameMap = createClsNameMap({
  elements: {
    desc: createClsNameMap({
      modifiers: ['nothing', 'result', 'list'],
    }),
    'suggest-link': createClsNameMap(),
  },
})('spelling-correction');

export type SpellingCorrectionProps = {
  correctQueries: Array<string>;
};

const SpellingCorrection = ({ correctQueries }: SpellingCorrectionProps) => {
  const { t } = useTranslation();

  if (!correctQueries || correctQueries.length === 0) return null;

  const getDidYouMean = () => {
    const sentenceParts = t('suggestion.didYouMean').split(/{{\s*terms\s*}}/g);
    const dymTerms = correctQueries.map((word, i) => {
      return (
        <a
          tabIndex={-1}
          key={word + i}
          className={clsNameMap.elm('suggest-link')}
          href={generateSearchPageHref(word)}
        >
          {word}
        </a>
      );
    });

    if (sentenceParts.length < 2) {
      return (
        <>
          {t('suggestion.didYouMean', { terms: ' ' })}
          {dymTerms}
        </>
      );
    } else {
      return (
        <>
          {sentenceParts[0]}
          {dymTerms}
          {sentenceParts[1]}
        </>
      );
    }
  };

  return (
    <div
      className={clsNameMap.root()}
      data-group='empty'
      data-aria-label={`${t('suggestion.didYouMean', { terms: correctQueries[0] })}`}
      data-label={`${t('suggestion.didYouMean', {
        terms: correctQueries.flatMap((word) => word),
      })}`}
    >
      <p className={mapModifiers(clsNameMap.desc, { list: true })}>{getDidYouMean()}</p>
    </div>
  );
};

export default SpellingCorrection;
