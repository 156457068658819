import './IntegrationProductRating.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import { InnerHTMLRenderer } from '@components/InnerHTMLRenderer';
import { createClsNameMap, mapModifiers } from '@utils';

export const clsNameMap = createClsNameMap({
  modifiers: ['left', 'center', 'right'],
})('integration-product-rating');

export type IntegrationProductRatingProps = {
  template?: string;
  textAlign?: string;
  ignoreCheckingTemplate?: boolean;
};

const IntegrationProductRating = ({
  template,
  textAlign,
  ignoreCheckingTemplate,
}: IntegrationProductRatingProps) => {
  if (!template) return null;

  if (ignoreCheckingTemplate)
    return (
      <span
        className={mapModifiers(clsNameMap, {
          [textAlign as string]: !!textAlign,
        })}
        dangerouslySetInnerHTML={{ __html: template }}
      />
    );

  return (
    <InnerHTMLRenderer
      className={mapModifiers(clsNameMap, {
        [textAlign as string]: !!textAlign,
      })}
      html={template}
    />
  );
};

export default registryComponent('IntegrationProductRating', IntegrationProductRating);
